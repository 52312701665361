import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';

import { useMySession } from '../contexts/SessionContext';

const getBaseUrl = () => {
    const env = process.env.NODE_ENV;
    return env === 'production' ? '' : 'http://localhost:8000';
};

const UserDocPage = () => {
    const [documents, setDocuments] = useState([]);
    const [error, setError] = useState(null);
    const { getMySessionID } = useMySession();

    console.log( getMySessionID() )

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const response = await axios.post(
                    `${getBaseUrl()}/api/read_user_document`,
                    { session_id: getMySessionID() },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                    }
                );

                setDocuments(response.data.results || []);
            } catch (error) {
                console.error('Error fetching documents:', error);
                setError('Failed to fetch documents. Please try again.');
            }
        };

        // 데이터가 이미 로드되었으면 추가 요청을 하지 않습니다.
        if (documents.length === 0) {
            fetchData();
        }
    }, []); // 빈 배열로 설정하여 마운트 시 한 번만 실행

    return (
        <Box width={["80%", "60%"]} margin="auto" marginTop="20px" position="relative">
            {error ? (
                <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>오류 발생!</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            ) : (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>이름</Th>
                            <Th>전화번호</Th>
                            <Th>사업명</Th>
                            <Th>신청일자</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {documents.map((doc) => (
                            <Tr key={doc.id}>
                                <Td>{doc.id}</Td>
                                <Td>{doc.user_name}</Td>
                                <Td>{doc.phone_number}</Td>
                                <Td>{doc.business_name}</Td>
                                <Td>{doc.created_at}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
};

export default UserDocPage;