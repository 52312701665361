// SignInPage.js
import React, { useState, useCallback } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Image,
    useToast,
    InputRightElement,
    InputGroup,
    IconButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { postSignIn } from '../Api'; // Api.js 파일 경로 확인
import { useGlobal } from '../contexts/GlobalContext'; // GlobalContext.js 파일 경로 확인

function SignInPage() {
    const navigate = useNavigate(); // 라우터 내비게이션 초기화
    const toast = useToast(); // 토스트 알림 초기화
    const { setIsSignedIn } = useGlobal(); // 글로벌 인증 상태 접근

    // 폼 입력 상태 관리
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // 로딩 상태 관리
    const [isLoading, setIsLoading] = useState(false);

    // 비밀번호 표시 상태 관리
    const [showPassword, setShowPassword] = useState(false);
    const handleShowClick = () => setShowPassword(!showPassword);

    // Sign in 핸들러
    const handleSignIn = useCallback(async () => {
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        console.log('Attempting to sign in with:', { trimmedEmail, trimmedPassword });

        // 기본 유효성 검사
        if (!trimmedEmail || !trimmedPassword) {
            toast({
                title: 'Missing Information',
                description: 'Please enter both email and password.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

        setIsLoading(true); // 로딩 시작

        try {
            const response = await postSignIn(trimmedEmail, trimmedPassword);
            console.log('SignIn Response:', response); // 응답 확인

            if (response.result === 1) { // 성공
                toast({
                    title: 'Sign In Successful',
                    description: response.message || 'You have successfully signed in.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                });

                setIsSignedIn(true); // 글로벌 상태 업데이트

                navigate('/doc'); // /doc 페이지로 이동
            } else { // 실패
                toast({
                    title: 'Sign In Failed',
                    description: response.message || 'Invalid email or password.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                });
            }
        } catch (error) {
            // 예기치 않은 오류 처리
            console.error('SignIn Error:', error);
            toast({
                title: 'An error occurred.',
                description: error.message || 'Unable to sign in. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
        } finally {
            setIsLoading(false); // 로딩 종료
        }
    }, [email, password, navigate, setIsSignedIn, toast]);

    // 폼 제출 핸들러
    const handleSubmit = (e) => {
        e.preventDefault(); // 기본 폼 제출 동작 방지
        handleSignIn(); // Sign in 핸들러 호출
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={4} w={'full'} maxW={'md'}>
                        <Heading fontSize={'2xl'}>하나되는 중구 함께하는 중구</Heading>
                        <FormControl id="email">
                            <FormLabel>아이디</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="이메일 주소를 입력해주세요"
                                required
                            />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>비밀번호</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="비밀번호를 입력해주세요"
                                    required
                                />
                                <InputRightElement width="3rem">
                                    <IconButton
                                        h="1.75rem"
                                        size="sm"
                                        onClick={handleShowClick}
                                        icon={showPassword ? <HiEyeOff /> : <HiEye />}
                                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Stack spacing={6}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}
                            >
                                {/* <Checkbox>Remember me</Checkbox> */}
                                {/* "Forgot password?" 버튼 수정 */}
                                <Button
                                    variant="link"
                                    color={'blue.500'}
                                    onClick={() => navigate('/')} // /doc 페이지로 이동
                                >
                                    홈화면으로 돌아가기
                                </Button>
                            </Stack>
                            <Button
                                type="submit"
                                // colorScheme={'orange'}
                                // variant={'solid'}
                                size='md'
                                border='2px'
                                borderColor='green.500'
                                isLoading={isLoading} // 로딩 상태 표시
                            >
                                로그인
                            </Button>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Image
                        alt={'Login Image'}
                        objectFit={'scale-down'}
                        src = {"https://myhand.junggu.seoul.kr/user_assets/images/img_logo.png"}
                    />
                </Flex>
            </Stack>
        </form>
    );
}

export default SignInPage;