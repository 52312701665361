import React from "react";
import { Flex, Text} from "@chakra-ui/react";
import { ScaleLoader} from "react-spinners"

const Loading = ({isLoading}) => {
    if (!isLoading) return null;
    return (
    <div>
        <ScaleLoader color="#ff0000" height="100px"/>
    </div>
    );
    };

const LoadingOverlay = ({ isLoading, bgColor}) => {
    if (!isLoading) return null;

    return (
        <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg={bgColor}
            zIndex="9999"
            justifyContent="center"
            alignItems="center"
            borderRadius="16px"
            flexDirection="row" // 가로 정렬 유지
        >
            <Loading isLoading={isLoading}/>
            <Text mt={2} fontSize={20}>검색 진행 중입니다.</Text>
        </Flex>
    );
};

export default LoadingOverlay;