import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { ChakraProvider, useToast } from '@chakra-ui/react';

import ServicePage from './pages/ServicePage';
import DocPage from './pages/DocPage';
import SignInPage from './pages/SignInPage';
import UserDocPage from './pages/UserDocPage';
import MainPage from './pages/MainPage';

import { useGlobal } from './contexts/GlobalContext';

import SidebarWithHeader from './components/NewNavigationBarComponent';

import { 
    calculateSessionExpireTime, calculateAccessTokenExpireTime, 
    calculateRefreshTokenExpireTime, unixToReadableTime 
} from './components/TimeCalculatingComponent';
import { getRefresh } from './Api';
import { v4 as uuidv4 } from 'uuid'; 
import Cookies from 'js-cookie';

const App = () => {
    const toast = useToast();
    const { isSignedIn, setIsSignedIn } = useGlobal();  // Access global state

    // Function to handle token refresh
    const handleGetRefresh = useCallback(async () => {
        try {
            const data = await getRefresh();

            if (data.result === 1) {
                toast({
                    title: '토큰이 자동 재발급 되었습니다.',
                    description: '토큰이 자동 재발급 되었습니다.',
                    status: 'info',
                    isClosable: true,
                    position: 'top'
                });

                setIsSignedIn(true);

                localStorage.setItem('session-exp', calculateSessionExpireTime());
                localStorage.setItem('access-token-exp', calculateAccessTokenExpireTime());
                localStorage.setItem('refresh-token-exp', calculateRefreshTokenExpireTime());
                return;
            } else {
                toast({
                    title: 'Fetch Failed',
                    description: '토큰 자동 갱신 실패. 재로그인 하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                setIsSignedIn(false);
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '토큰 자동 갱신 실패. 재로그인 하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setIsSignedIn(false);
            return;
        }
    }, [toast, setIsSignedIn]);

    // Function to check token expiration times
    const checkExpireTime = useCallback(async () => {
        const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
        const accessTokenExpireTime = parseInt(localStorage.getItem('access-token-exp'));
        const refreshTokenExpireTime = parseInt(localStorage.getItem('refresh-token-exp'));

        if (sessionExpireTime <= Date.now() && sessionExpireTime !== null) {
            console.log("1. 세션 만료됨");
            setIsSignedIn(false);
            return false;
        } else if (sessionExpireTime > Date.now() && sessionExpireTime !== null) {
            console.log("2. 세션 시간 갱신됨");
            localStorage.setItem('session-exp', calculateSessionExpireTime());
        }

        if (sessionExpireTime > Date.now() && accessTokenExpireTime <= Date.now() && refreshTokenExpireTime > Date.now()) {
            console.log("3. 엑세스 토큰 만료됨, 갱신 시도");
            await handleGetRefresh();
        }

        let returnValue = {
            nowTime: unixToReadableTime(Date.now()),
            sessionExpireTime: unixToReadableTime(sessionExpireTime),
            accessTokenExpireTime: unixToReadableTime(accessTokenExpireTime),
            refreshTokenExpireTime: unixToReadableTime(refreshTokenExpireTime)
        };
        return returnValue;
    }, [handleGetRefresh, setIsSignedIn]);

    // Function to handle UUID
    const checkUUID = () => {
        let uuid = Cookies.get('uuid');
    
        if (!uuid) {
          uuid = uuidv4();
          Cookies.set('uuid', uuid, { expires: 365 });
        }
    
        return uuid;
    };
    
    useEffect(() => {
        checkUUID();
    }, []);

    // Nested component to handle routing and conditional sidebar
    const MainContent = () => {
        const location = useLocation();
        const hideSidebarRoutes = ['/signin', '/']; // Define routes where sidebar should be hidden

        const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);

        return (
            <>
                {!shouldHideSidebar && (
                    <SidebarWithHeader 
                        checkExpireTime={checkExpireTime} 
                    />
                )}
                <Routes>
                    <Route 
                        path='/chatting' 
                        element={
                            <ServicePage 
                                checkExpireTime={checkExpireTime}
                            />} 
                    />
                    <Route 
                        path='/' 
                        element={
                            <MainPage
                                checkExpireTime={checkExpireTime}
                            />} 
                    />                           
                    <Route
                        path='/signin'
                        element={
                            <SignInPage/>}
                    />
                    {(isSignedIn && (
                        <Route
                        path='/doc'
                        element={
                            <DocPage
                                checkExpireTime={checkExpireTime}
                            />} 
                    />
                    ))}                    
                    <Route
                        path='/requests'
                        element={
                            <UserDocPage/>
                        }
                    />
                    {/* Optional: Add a catch-all route for 404 Not Found */}
                    {/* <Route path="*" element={<NotFoundPage />} /> */}
                </Routes>
            </>
        );
    };

    return (
        <ChakraProvider>
            <BrowserRouter>
                <MainContent />
            </BrowserRouter>           
        </ChakraProvider>
    );
}

export default App;