import React, { createContext, useState, useContext, useEffect } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [isSignedIn, setIsSignedIn] = useState(() => {
        const sessionExpireTime = localStorage.getItem('session-exp');
        const accessToken = localStorage.getItem('access-token');
        const refreshToken = localStorage.getItem('refresh-token');

        if (sessionExpireTime && accessToken && refreshToken) {
            return parseInt(sessionExpireTime) > Date.now(); // true
        }
        return false;
    });

    useEffect(() => {
        if (!isSignedIn) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('session-exp');
            localStorage.removeItem('access-token-exp');
            localStorage.removeItem('refresh-token-exp');
        }
    }, [isSignedIn]);

    return (
        <GlobalContext.Provider value={{ isSignedIn, setIsSignedIn }}>
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobal must be used within a GlobalProvider');
    }
    return context;
};