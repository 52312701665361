
import React, { createContext, useContext } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {

    const saveMySessionID = (mySessionID) => {
        localStorage.setItem('my-session-id', mySessionID);
    }

    const getMySessionID = () => {
        return localStorage.getItem('my-session-id');
    }

    // const deleteMySessionID = () => {
    //     localStorage.removeItem('my-session-id');
    // }

    return (
        <SessionContext.Provider value={{ saveMySessionID, getMySessionID }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useMySession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useGlobal must be used within a GlobalProvider');
    }
    return context;
};